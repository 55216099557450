import React, { useState, useEffect } from 'react';
import Gradients from '../components/Gradients';
import ProductImages from '../components/ProductImages';
import Info from '../components/Info/Info';
import {Puff} from "react-loader-spinner";
import { useHistory, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import Confirm from '../components/Confirm';
import ErrorAlert from '../components/ErrorAlert';
// import '../styles/main.scss'
import logo from '../assets/img/logo.png';
import WaCheck from "./WaCheck"

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Home = ({ showConfirm, hideConfirm, setHaveInterest, _api }) => {
  let query = useQuery();
  const [tutorId, setTutorId] = useState();
  const [sessionId, setSessionId] = useState();
  const [tutorPay, setTutorPay] = useState();
  const [type, setType] = useState();
  const [subject, setSubject] = useState();
  const [date_time, setDate_time] = useState();
  const [date_time2,setDate_time2]=useState();
  const [date_time3, setDate_time3] = useState();
  const [date_time4,setDate_time4]=useState();
  const [duration, setDuration] = useState();
  const [folderlink, setFolderlink] = useState();
  const [filelink1, setFilelink1] = useState([]);
  const [tutorInterested, setTutorInterested] = useState(false);
  const [isAssignTutor, setIsAssignTutor] = useState(false);
  const [workDetails, setWorkDetails] = useState();
  const [isTutor, setIsTutor] = useState(false);
  const [tutor_email, setTutor_email] = useState("");
  const [clientId, setClientId] = useState("");
  const [work_status, setWork_status] = useState("");
  const [childSolnLink, setChildSolnLink] = useState("");
  const [errorMsg, setErrorMsg] = useState();
  const [loading,setLoading] = useState(true)
  const [waChecked,setWaChecked]=useState(false)
  
  // let query = useQuery();
  const { ssnId, tutId } = useParams();




  const startdefault = async () => {
    setLoading(true)

    

    const tutor_show = await axios.post(
      `${_api}/getTutorFormData`,
      {
        sessionId: ssnId,
        tutorId: tutId,
      }
    );
    let typeVal = tutor_show?.data?.type;
    console.log(tutor_show.data );
    if (tutor_show.data.success || tutor_show.data.status==='interested' ) {
      const tutor_pay_total = tutor_show.data.tutor_dealt_amount;
      const type = tutor_show.data.type;
      const subject = tutor_show.data.subject;
      const date_time = tutor_show.data.date_time;
      const tutor_deadline=tutor_show?.data?.tutor_deadline
      // const duration = tutor_show.data.duration;
      const duration = tutor_show?.data?.tutor_duration;
      const filelink = tutor_show.data.filelink;
      const folderlink_ = tutor_show.data.folderlink;
      const workDetails = tutor_show.data.client_comments;

      const date_time1 = moment(date_time).format('MMMM Do YYYY, h:mm a');
      const date_time2=moment(date_time).format('MM/DD/YYYY hh:mm:ss A');

      if(tutor_deadline!=='' && tutor_deadline!== null && tutor_deadline!==undefined)
      
      {
      const date_time31 = moment(tutor_deadline).format('MMMM Do YYYY, h:mm a');
      const date_time41=moment(tutor_deadline).format('MM/DD/YYYY hh:mm:ss A');
       // regarding tutor_deadline
       setDate_time3(date_time31);
       setDate_time4(date_time41)
      }

      if(duration !== '' && duration!==null && duration!==undefined)
      {
      let hr = duration?.slice(0, 2);
      let min = duration?.slice(4);

      var duration1 = hr + 'h ' + min + 'm';
      setDuration(duration1);
      }
      console.log({ filelink: filelink });

      setTutorPay(tutor_pay_total);
      setType(type);
      setSubject(subject);
      setDate_time(date_time1);
      setDate_time2(date_time2)
      
     

     
      setWorkDetails(workDetails);
      setFolderlink(folderlink_);
      
      setFilelink1([...filelink1, ...filelink]);
    }

    if (!tutor_show.data.success && tutor_show.data.status === 'interested') {
      setTutor_email(tutor_show?.data?.tutor_email)
      setClientId(tutor_show?.data?.clientId)
      setWork_status(tutor_show?.data?.work_status)
      setIsTutor(true);
      setTutorInterested(true)
      setErrorMsg('You have already shown your Interest');



      // assign tutor case
      if( query.get('accept_task') == 'success'){
        const childSolnFunc=async()=>{
        const payload = {
          status: 'accepted'
        };
        const resp = await axios.put(`${_api}/assigned-tutor-acceptance-status/${ssnId}/${tutId}`, payload, {
          headers: ''
        })
        if(resp.data.success==true ){
          setIsAssignTutor(true)
          setIsTutor(false)
          if(typeVal && typeVal !== 'Trial Course'){

          const childSolution=await axios.post(`${_api}/childSolutionFolderCreate`,{
            // const childSolution=await axios.post(`http://localhost:8600/childSolutionFolderCreate`,{
            emailId:tutor_show?.data?.tutor_email,
            tutorId:tutId,
            clientId:tutor_show?.data?.clientId,
            sessionId:ssnId,
            sessionStatus:tutor_show?.data?.work_status,
            assignmentStatus:"assigned"
          })
          if(childSolution?.data?.solnFolder){
            setLoading(false)
            setChildSolnLink(childSolution?.data?.solnFolder)
          }
        }else{
          setLoading(false)
        }
        }      
      }
  
      childSolnFunc()
        
      }
      else{
        setLoading(false)
      }
      
    }
    else if(!tutor_show.data.success && tutor_show.data.status === 'cancelled') {
      setIsTutor(true);
      const childSolution=await axios.post(`${_api}/childSolutionFolderCreate`,{
        // const childSolution=await axios.post(`http://localhost:8800/v1/api/orders/childSolutionFolderCreate`,{
      emailId:tutor_show?.data?.tutor_email,
        tutorId:tutId,
        clientId:tutor_show?.data?.clientId,
        sessionId:ssnId,
        sessionStatus:tutor_show?.data?.work_status,
        assignmentStatus:"unassigned"
      })
      if(childSolution?.data){
        console.log("child folder deleted")
      }
      // setErrorMsg('Sorry, You are late to show your Interest');
      setLoading(false)
      setErrorMsg('Sorry, You are not assigned for this task.');
    }
    else if (!tutor_show.data.success && tutor_show.data.status == 'error') {
      setIsTutor(true);
      setLoading(false)
      setErrorMsg('404! NOT FOUND');
    }
    else {
      setLoading(false)
      setIsTutor(false);
    }
  };



  useEffect(() => {
    setTutorId(tutId);
    setSessionId(ssnId);
    console.log({ SESSIONID: sessionId });

if (waChecked)
    {startdefault();}

  }, [waChecked]);

  
    // if(tutorInterested && query.get('accept_task') == 'success'){
    //   const childSolnFunc=async()=>{
    //   const payload = {
    //     status: 'accepted'
    //   };
    //   const resp = await axios.put(`${_api}/assigned-tutor-acceptance-status/${ssnId}/${tutId}`, payload, {
    //     headers: ''
    //   })
    //   if(resp){
    //     setIsAssignTutor(true)
    //   }
    //   setIsTutor(false)

      
    //   const childSolution=await axios.post(`${_api}/childSolutionFolderCreate`,{
    //     emailId:tutor_email,
    //     tutorId:tutId,
    //     clientId:clientId,
    //     sessionId:ssnId,
    //     sessionStatus:work_status
    //   })
    //   if(childSolution?.data?.solnFolder){
        
    //     setChildSolnLink(childSolution?.data?.solnFolder)
    //   }
    // }

    // childSolnFunc()
      
    // }
  

  // useEffect(() => {
  //   startdefault()
  // }, []);


  const calculateTimeLeft = () => {
    let year = new Date().getFullYear();
    // console.log({dateeeee:props.date_time2})
    let difference = +new Date(date_time4) - +Date.now();
  
    let timeLeft = {};
  
    if (difference > 0) {
      timeLeft = {
        Days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        Hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        Min: Math.floor((difference / 1000 / 60) % 60),
        Sec: Math.floor((difference / 1000) % 60),
      };
    }
  
    return timeLeft;
  };
  
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  // console.log({timeLeft})
  
  
  const timerComponents = [];
  // console.log({timerComponents})
  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }
  
  
    timerComponents.push(
      <span style={{display:"flex",paddingRight:"5px" ,fontSize:"35px" ,alignItems:"baseline"}}>
       {timeLeft[interval]}<span style={{fontSize:"15px",marginLeft:"3px"}}>{interval}</span>
      </span>
    );
    // timerComponents.push("left")
  });
  // timerComponents.push("left")
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  
    return () => clearTimeout(timer);
  });
  



  return (

    <>
    {!waChecked &&  <div style={{display:"flex",justifyContent:"center",marginTop:"15%"}}><WaCheck _api={_api} tutId={tutId} ssnId={ssnId} setWaChecked={setWaChecked} /></div>}
    {waChecked && <div className='Home'>


      {loading && <div style={{marginLeft: '45%',marginTop: '20%'}}>
       <Puff
        heigth="100"
        width="100"
        color='#01b1b1'
        ariaLabel='loading'
      />
  </div>}


  {!loading && <div>
      {(isTutor) && <ErrorAlert errorMsg={errorMsg} />}
      {/* {isTutor && query.get('accept_task') == 'success' && <ErrorAlert errorMsg={"You have accepted this session request"} />} */}
      
      {(!isTutor) && (
        <div className='container' style={{overflowY:"scroll"}}>
          <div className='card' style={{marginTop:"5rem"}}>
            <div className='shoeBackground'>
              <Gradients
                date_time={date_time}
                date_time3={date_time3}
                duration={duration}
                type={type}
              />

              <h1 className='nike'>UrDoer</h1>
              
              <ProductImages />
            </div>
            <Info
              date_time2={date_time2}
              date_time4={date_time4}
              timerComponents={timerComponents}
              tutorPay={tutorPay}
              sessionId={sessionId}
              type={type}
              subject={subject}
              filelink={filelink1}
              folderlink={folderlink}
              workDetails={workDetails}
              tutorInterested={tutorInterested}
              isAssignTutor={isAssignTutor}
              showConfirm={showConfirm}
              childSolnLink={childSolnLink}
              setHaveInterest={setHaveInterest}
            />
          </div>
        </div>
      )}
      </div>}
    </div>}
    </>
  );
};

export default Home;
