import React from 'react';
// import '../../styles/main.scss'

const Info = (props) => {
  let N = props.filelink.length;
  //  var i=0
  // var id= Array.from({length: N}, (_, index) => index + 1)

  const selectHandler = (e) => {
    let value = e.target.value;
    console.log(value);

    value && window.open(value, '_blank');
  };

  const confirmHandler = () => {
    props.showConfirm();
    props.setHaveInterest(true);
  };
  const confirmHandler1 = () => {
    props.showConfirm();
    props.setHaveInterest(false);
  };
  const shoeName = (
    <div className='shoeName'>
      <div 
      style={{marginTop: "-3%",
        marginBottom: "5%",
        fontSize: "x-large",
        color:"white",
        justifyContent: "center",
        backgroundColor:"#2175f5",
        display: "inline-flex",
        minWidth: "-webkit-fill-available"
        }}>
      {props.timerComponents.length ? props.timerComponents  : <span>Time's up!</span>}
      </div>
      <div>
        <h1 className='big'>Session ID -</h1>
        <span className='new'>
          {props?.type?.slice(0, 4) + props.sessionId}
        </span>
      </div>
      <h3 className='small'>{props.type}</h3>
    </div>
  );

  const description = (
    <div className='description'>
      <h3 className='title'>{props.subject}</h3>
      <p className='text' style={{height:"10rem",overflowY:"scroll"}}>{(props.type == 'Live Course' || props.type == 'Trial Course' ? 'Course' : "Work")} Details: {props.workDetails}.</p>
    </div>
  );

  const ColorContainer = (
    <div className='color-container'>
      {/* <h3 className='title'>Files</h3>
      <select
        name='file links'
        placeholder='Files'
        style={{ width: '200px' }}
        onChange={selectHandler}
      >
        <option value=''>-open file-</option>
        {props.filelink.map((link, index) => (
          <option value={link}>File-{index + 1}</option>
        ))}
      </select> */}
      <h3 className='title' style={{marginTop: '20px'}}>G-Drive Link</h3>
      <a style={{fontSize: '14px',marginRight:"50px"}} href={props.folderlink} target='_blank'>Question Folder</a>
     { props?.childSolnLink?.length!==0 && <a style={{fontSize: '14px'}} href={props?.childSolnLink} target='_blank'>Upload Solutions Here</a>}
    </div>
  );

  const SizeContainer = (
    <div className='size-container row buy-price'>
      <div>
        <h3 className='title'>Amount</h3>
      </div>
      <div className='price'>
        <i className='fas fa-rupee-sign'></i>
        <h1>{props.tutorPay / 100}</h1>
      </div>
    </div>
  );

  const BuySection = (
    <div className='buy-price'>
      <button
        onClick={confirmHandler}
        style={{ background: 'gray', border: 'none' }}
        className='buy'
      >
        <i className='fas fa-times'></i>Not Interested
      </button>
      <button
        onClick={confirmHandler1}
        style={{ border: 'none' }}
        className='buy'
      >
        <i className='fas fa-check'></i>Interested
      </button>
    </div>
  );

  return (
    <div className='info'>
      {(props.isAssignTutor ) &&<div style={{marginBottom: "20px",textAlign:"center",color:"#ff0000",fontSize: "20px"}}><b>You have accepted this task!!</b></div>}
      {shoeName}
      {description}
      {ColorContainer}
      {SizeContainer}
      {(props.tutorInterested || props.timerComponents.length===0) ? null: BuySection }
    </div>
  );
};

export default Info;
