import React from "react";

const Gradients = (props) => {
  return (
    <div className="gradients">
      <div className="gradient behind" color="blue"></div>
      <div className="gradient" color="red"></div>
      <div className="gradient" color="green"></div>
      <div className="gradient" color="orange"></div>
      <div className="gradient" color="black"></div>
      
      {(props.type==='Live Session' || props.type=== 'Trial Course' || props.type==='Live Course' ) && <p className='pk'>
        <span style={{opacity:0.4}}>Date & Time</span>
        <br/><span>{props.date_time3} </span>
     { (props.type !== 'Trial Course' && props.type !=='Live Course') && <><br/> <br/> <span style={{opacity:0.4}}>Duration - </span> {props.duration} </>}
     </p>
      }
      {(props.type==='Assignment' || props.type==="Project") && <p className='pk'><br/><br/><span style={{opacity:0.4}}> Deadline </span><br/>{props.date_time3}</p>}
    </div>
  );
};

export default Gradients;
